.floating-label-container {
    border: 1px solid;
    border-radius: 6px;
    position: relative;
    margin: 10px;
    margin-top: 15px;
    display: inline-flex;
    justify-content: left;
    flex-grow: 4;
    flex-wrap: wrap;
    overflow: no-content;
}

.floating-label-up {
    position: absolute;
    background-color: #fff;
    left: 6px !important;
    top: -10px !important;
    font-size: 0.75em !important;
    z-index: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 3px;
}