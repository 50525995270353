.customTabs
{
  border-bottom:solid 1px #ccc !important;
}

.customTab1, .customTab2, .customTab3, .customTab4, .customTab5, .customTab6, .customTab7 {
  border-radius: 12px; 
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border:solid 1px #ccc;
  border-top-color:transparent;
  outline: none;
  width: 100%;
}

@media screen and (min-width: 1000px) {
  .customTabsN a.active {
    background-color: white;
    border:solid 1px #fff !important;
    font-weight:bolder;
    border-bottom:solid 0px #ccc !important;
    background:white !important;
    font-size:1rem;
    width:12.5%;
  }

  .customTabsN a
  {
    outline: solid 1px #fff;
    border:solid 0px !important;
    border-bottom:solid 0px #ddd !important;
    background-color:#f0f;
    font-family: "Dancing Script";
    font-size:1rem;
    border-radius: 5;
    border-top-left-radius: 1px !important;
    border-top-right-radius: 4px !important;
    margin-left: 0px;
    font-weight: bolder;
    width:12.5%;
    padding-bottom: 2px !important;
    padding-top: 2px !important;
  }
}

@media screen and (max-width: 1000px) and (min-width: 600px) {
  .customTabsN a.active {
    background-color: white;
    border:solid 1px #fff !important;
    font-weight:bolder;
    border-bottom:solid 0px #ddd !important;
    background:white !important;
    font-size:0.96rem;
    width:12.5%;
  }

  .customTabsN a
  {
    outline: solid 1px #fff;
    border:solid 0px !important;
    border-bottom:solid 0px grey !important;
    background-color:#f0f;
    font-family: "Dancing Script";
    font-size:0.96rem;
    border-radius: 0;
    border-top-left-radius: 1px !important;
    border-top-right-radius: 4px !important;
    margin-left: 0px;
    font-weight: bolder;
    width:12.5%;
  }
}

@media screen and (max-width: 600px) and (min-width: 400px) {
  .customTabsN a.active {
    background-color: white;
    border:solid 1px #fff !important;
    font-weight:bolder;
    border-bottom:solid 0px #ccc !important;
    background:white !important;
    font-size:0.9rem;
    width:12.5%;
    padding:12px 2px;
  }

  .customTabsN a
  {
    outline: solid 1px #fff;
    border:solid 0px !important;
    border-bottom:solid 0px #ddd !important;
    background-color:#f0f;
    font-family: "Dancing Script";
    font-size:0.9rem;
    border-radius: 0;
    border-top-left-radius: 1px !important;
    border-top-right-radius: 4px !important;
    margin-left: 0px;
    font-weight: bolder;
    width:12.5%;
    padding:12px 2px;
  }
}



@media screen and (max-width: 400px) {
  .customTabsN a.active {
    background-color: white;
    border:solid 1px #fff !important;
    font-weight:bolder;
    border-bottom:solid 0px #ddd !important;
    background:white !important;
    font-size:0.96rem;
    width:25%;
  }

  .customTabsN a
  {
    outline: solid 1px #fff;
    border:solid 0px !important;
    border-bottom:solid 0px #ddd !important;
    background-color:#f0f;
    font-family: "Dancing Script";
    font-size:0.96rem;
    border-radius: 0;
    border-top-left-radius: 1px !important;
    border-top-right-radius: 4px !important;
    margin-left: 0px;
    font-weight: bolder;
    width:25%;
  }
}


.customTabs a
{
  outline: none;
  border:0px !important;
  border-bottom:solid 0px #ddd !important;
  background-color:#f0f;
  font-family: "Montserrat";
  font-size:0.75rem;
  border-radius: 0;
  border-top-left-radius: 1px !important;
  border-top-right-radius: 4px !important;
  margin-left: 0px;
  font-weight: bolder;
}

.customTabs a.active {
  background-color: white;
  border:solid 1px #ccc !important;
  font-weight:bolder;
  border-bottom:solid 0px #ddd !important;
  background:white !important;
  font-size:0.75rem;
}

  .customTabs a.disabled {
    opacity: 0;
    color:#d00 !important;
    filter: grayscale(100%);
    visibility: hidden;
    border-bottom:solid 0px #ddd !important;
  }

  .customTabs-color-n a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-n-first);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(1) {
    color:rgb(var(--color-raw-1), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-n a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-n-second);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(2) {
      color:rgb(var(--color-raw-2), 1.0);
      border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-n-third);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(3) {
      color:rgb(var(--color-raw-3), 1.0);
      border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-n-fourth);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(4) {
      color:rgb(var(--color-raw-4), 1.0);
      border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-n-fifth);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(5) {
      color:rgb(var(--color-raw-5), 1.0);
      border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-n-sixth);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(6) {
      color:rgb(var(--color-raw-6), 1.0);
      border-bottom:solid 0px #ddd !important;
  }

  .customTabs-color-n a:nth-child(7) {
    color:#fff;
    background: var(--tab-grad-n-seventh);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(7) {
      color:rgb(var(--color-raw-7), 1.0);
      border-bottom:solid 0px #ddd !important;
  }

  .customTabs-color-n a:nth-child(8) {
    color:#fff;
    background: var(--tab-grad-n-eigth);
    border-bottom:solid 0px #ddd !important;
  }
  
  .customTabs-color-n a.active:nth-child(8) {
      color:rgb(var(--color-raw-8), 1.0);
      border-bottom:solid 0px #ddd !important;
  }






  
  .customTabs-color-1 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-1-first);
  }
  
  .customTabs-color-1 a.active:nth-child(1) {
    color:rgb(var(--color-raw-1), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-1 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-1-second);
  }
  
  .customTabs-color-1 a.active:nth-child(2) {
      color:rgb(var(--color-raw-1), 1.0);
  }
  
  .customTabs-color-1 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-1-third);
  }
  
  .customTabs-color-1 a.active:nth-child(3) {
      color:rgb(var(--color-raw-1), 1.0);
  }
  
  .customTabs-color-1 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-1-fourth);
  }
  
  .customTabs-color-1 a.active:nth-child(4) {
      color:rgb(var(--color-raw-1), 1.0);
  }
  
  .customTabs-color-1 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-1-fifth);
  }
  
  .customTabs-color-1 a.active:nth-child(5) {
      color:rgb(var(--color-raw-1), 1.0);
  }
  
  .customTabs-color-1 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-1-sixth);
  }
  
  .customTabs-color-1 a.active:nth-child(6) {
      color:rgb(var(--color-raw-1), 1.0);
  }




  .customTabs-color-2 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-2-first);
  }
  
  .customTabs-color-2 a.active:nth-child(1) {
    color:rgb(var(--color-raw-2), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-2 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-2-second);
  }
  
  .customTabs-color-2 a.active:nth-child(2) {
      color:rgb(var(--color-raw-2), 1.0);
  }
  
  .customTabs-color-2 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-2-third);
  }
  
  .customTabs-color-2 a.active:nth-child(3) {
      color:rgb(var(--color-raw-2), 1.0);
  }
  
  .customTabs-color-2 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-2-fourth);
  }
  
  .customTabs-color-2 a.active:nth-child(4) {
      color:rgb(var(--color-raw-2), 1.0);
  }
  
  .customTabs-color-2 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-2-fifth);
  }
  
  .customTabs-color-2 a.active:nth-child(5) {
      color:rgb(var(--color-raw-2), 1.0);
  }
  
  .customTabs-color-2 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-2-sixth);
  }
  
  .customTabs-color-2 a.active:nth-child(6) {
      color:rgb(var(--color-raw-2), 1.0);
  }




  .customTabs-color-3 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-3-first);
  }
  
  .customTabs-color-3 a.active:nth-child(1) {
    color:rgb(var(--color-raw-3), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-3 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-3-second);
  }
  
  .customTabs-color-3 a.active:nth-child(2) {
      color:rgb(var(--color-raw-3), 1.0);
  }
  
  .customTabs-color-3 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-3-third);
  }
  
  .customTabs-color-3 a.active:nth-child(3) {
      color:rgb(var(--color-raw-3), 1.0);
  }
  
  .customTabs-color-3 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-3-fourth);
  }
  
  .customTabs-color-3 a.active:nth-child(4) {
      color:rgb(var(--color-raw-3), 1.0);
  }
  
  .customTabs-color-3 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-3-fifth);
  }
  
  .customTabs-color-3 a.active:nth-child(5) {
      color:rgb(var(--color-raw-3), 1.0);
  }
  
  .customTabs-color-3 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-3-sixth);
  }
  
  .customTabs-color-3 a.active:nth-child(6) {
      color:rgb(var(--color-raw-3), 1.0);
  }



  .customTabs-color-4 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-4-first);
  }
  
  .customTabs-color-4 a.active:nth-child(1) {
    color:rgb(var(--color-raw-4), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-4 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-4-second);
  }
  
  .customTabs-color-4 a.active:nth-child(2) {
      color:rgb(var(--color-raw-4), 1.0);
  }
  
  .customTabs-color-4 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-4-third);
  }
  
  .customTabs-color-4 a.active:nth-child(3) {
      color:rgb(var(--color-raw-4), 1.0);
  }
  
  .customTabs-color-4 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-4-fourth);
  }
  
  .customTabs-color-4 a.active:nth-child(4) {
      color:rgb(var(--color-raw-4), 1.0);
  }
  
  .customTabs-color-4 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-4-fifth);
  }
  
  .customTabs-color-4 a.active:nth-child(5) {
      color:rgb(var(--color-raw-4), 1.0);
  }
  
  .customTabs-color-4 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-4-sixth);
  }
  
  .customTabs-color-4 a.active:nth-child(6) {
      color:rgb(var(--color-raw-4), 1.0);
  }



  .customTabs-color-5 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-5-first);
  }
  
  .customTabs-color-5 a.active:nth-child(1) {
    color:rgb(var(--color-raw-5), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-5 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-5-second);
  }
  
  .customTabs-color-5 a.active:nth-child(2) {
      color:rgb(var(--color-raw-5), 1.0);
  }
  
  .customTabs-color-5 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-5-third);
  }
  
  .customTabs-color-5 a.active:nth-child(3) {
      color:rgb(var(--color-raw-5), 1.0);
  }
  
  .customTabs-color-5 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-5-fourth);
  }
  
  .customTabs-color-5 a.active:nth-child(4) {
      color:rgb(var(--color-raw-5), 1.0);
  }
  
  .customTabs-color-5 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-5-fifth);
  }
  
  .customTabs-color-5 a.active:nth-child(5) {
      color:rgb(var(--color-raw-5), 1.0);
  }
  
  .customTabs-color-5 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-5-sixth);
  }
  
  .customTabs-color-5 a.active:nth-child(6) {
      color:rgb(var(--color-raw-5), 1.0);
  }



  .customTabs-color-6 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-6-first);
  }
  
  .customTabs-color-6 a.active:nth-child(1) {
    color:rgb(var(--color-raw-6), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-6 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-6-second);
  }
  
  .customTabs-color-6 a.active:nth-child(2) {
      color:rgb(var(--color-raw-6), 1.0);
  }
  
  .customTabs-color-6 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-6-third);
  }
  
  .customTabs-color-6 a.active:nth-child(3) {
      color:rgb(var(--color-raw-6), 1.0);
  }
  
  .customTabs-color-6 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-6-fourth);
  }
  
  .customTabs-color-6 a.active:nth-child(4) {
      color:rgb(var(--color-raw-6), 1.0);
  }
  
  .customTabs-color-6 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-6-fifth);
  }
  
  .customTabs-color-6 a.active:nth-child(5) {
      color:rgb(var(--color-raw-6), 1.0);
  }
  
  .customTabs-color-6 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-6-sixth);
  }
  
  .customTabs-color-6 a.active:nth-child(6) {
      color:rgb(var(--color-raw-6), 1.0);
  }


  .customTabs-color-7 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-7-first);
  }
  
  .customTabs-color-7 a.active:nth-child(1) {
    color:rgb(var(--color-raw-7), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-7 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-7-second);
  }
  
  .customTabs-color-7 a.active:nth-child(2) {
      color:rgb(var(--color-raw-7), 1.0);
  }
  
  .customTabs-color-7 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-7-third);
  }
  
  .customTabs-color-7 a.active:nth-child(3) {
      color:rgb(var(--color-raw-7), 1.0);
  }
  
  .customTabs-color-7 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-7-fourth);
  }
  
  .customTabs-color-7 a.active:nth-child(4) {
      color:rgb(var(--color-raw-7), 1.0);
  }
  
  .customTabs-color-7 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-7-fifth);
  }
  
  .customTabs-color-7 a.active:nth-child(5) {
      color:rgb(var(--color-raw-7), 1.0);
  }
  
  .customTabs-color-7 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-7-sixth);
  }
  
  .customTabs-color-7 a.active:nth-child(6) {
      color:rgb(var(--color-raw-7), 1.0);
  }



  .customTabs-color-8 a:nth-child(1) {
    color:#fff;
    /* background: linear-gradient(90deg, #3F51B5, #003F9F); */
    background: var(--tab-grad-8-first);
  }
  
  .customTabs-color-8 a.active:nth-child(1) {
    color:rgb(var(--color-raw-8), 1.0);
    border-bottom:solid 0px white !important;
  }
  
  .customTabs-color-8 a:nth-child(2) {
    color:#fff;
    background: var(--tab-grad-8-second);
  }
  
  .customTabs-color-8 a.active:nth-child(2) {
      color:rgb(var(--color-raw-8), 1.0);
  }
  
  .customTabs-color-8 a:nth-child(3) {
    color:#fff;
    background: var(--tab-grad-8-third);
  }
  
  .customTabs-color-8 a.active:nth-child(3) {
      color:rgb(var(--color-raw-8), 1.0);
  }
  
  .customTabs-color-8 a:nth-child(4) {
    color:#fff;
    background: var(--tab-grad-8-fourth);
  }
  
  .customTabs-color-8 a.active:nth-child(4) {
      color:rgb(var(--color-raw-8), 1.0);
  }
  
  .customTabs-color-8 a:nth-child(5) {
    color:#fff;
    background: var(--tab-grad-8-fifth);
  }
  
  .customTabs-color-8 a.active:nth-child(5) {
      color:rgb(var(--color-raw-8), 1.0);
  }
  
  .customTabs-color-8 a:nth-child(6) {
    color:#fff;
    background: var(--tab-grad-8-sixth);
  }
  
  .customTabs-color-8 a.active:nth-child(6) {
      color:rgb(var(--color-raw-8), 1.0);
  }
  