:root {
    /*--color-scheme-1: #f569d9;*/
    /*--color-scheme-2: #b533e8;*/
    /*--color-scheme-3: #8866ff;*/
    /*--color-scheme-4: #563bdb;*/
    /*--color-scheme-5: #3548f2;*/
    /*--color-scheme-6: #0798e6;*/
    /*--color-scheme-7: #00c8af;*/
    /*--color-scheme-8: #69f58a;*/
    --color-raw-1: 242, 196, 56;
    --color-raw-2: 242, 154, 46;
    --color-raw-3: 242, 89, 54;
    --color-raw-4: 242, 29, 47;
    --color-raw-5: 170, 0, 28;
    --color-raw-6: 95, 205, 217;
    --color-raw-7: 60, 112, 145;
    --color-raw-8: 32, 44, 89;
    --color-raw-pink: 221, 114, 235;
    --color-raw-blue: 72, 157, 255;
    --color-raw-grey: 222, 222, 222;
    --color-raw-lightgrey: 245, 247, 250;
    --color-raw-white: 255, 255, 255;
    --color-scheme-1: rgba(var(--color-raw-1), 1.0);
    --color-scheme-2: rgba(var(--color-raw-2), 1.0);
    --color-scheme-3: rgba(var(--color-raw-3), 1.0);
    --color-scheme-4: rgba(var(--color-raw-4), 1.0);
    --color-scheme-5: rgba(var(--color-raw-5), 1.0);
    --color-scheme-6: rgba(var(--color-raw-6), 1.0);
    --color-scheme-7: rgba(var(--color-raw-7), 1.0);
    --color-scheme-8: rgba(var(--color-raw-8), 1.0);
    --color-scheme-pink: rgba(var(--color-raw-pink), 1.0);
    --color-scheme-white: rgba(var(--color-raw-white), 1.0);
    --color-scheme-blue: rgba(var(--color-raw-blue), 1.0);
    --color-scheme-grey: rgba(var(--color-raw-grey), 1.0);
    --color-scheme-lightgrey: rgba(var(--color-raw-lightgrey), 1.0);
    --color-scheme-text-1: #ffffff;
    --color-scheme-text-2: #ffffff;
    --color-scheme-text-3: #ffffff;
    --color-scheme-text-4: #ffffff;
    --color-scheme-text-5: #ffffff;
    --color-scheme-text-6: #ffffff;
    --color-scheme-text-7: #ffffff;
    --color-scheme-text-8: #ffffff;
    --color-scheme-text-pink: #ffffff;
    --color-scheme-text-blue: #ffffff;
    --color-scheme-text-grey: #ffffff;
    --color-scheme-text-lightgrey: #ffffff;
    --color-scheme-text-link: #0b1d3f;
    --color-grad-deg: 105deg;
    --color-grad-spread: 400%;
    --color-grad-1: linear-gradient(var(--color-grad-deg), var(--color-scheme-1) 0%, white var(--color-grad-spread));
    --color-grad-2: linear-gradient(var(--color-grad-deg), var(--color-scheme-2) 0%, white var(--color-grad-spread));
    --color-grad-3: linear-gradient(var(--color-grad-deg), var(--color-scheme-3) 0%, white var(--color-grad-spread));
    --color-grad-4: linear-gradient(var(--color-grad-deg), var(--color-scheme-4) 0%, white var(--color-grad-spread));
    --color-grad-5: linear-gradient(var(--color-grad-deg), var(--color-scheme-5) 0%, white var(--color-grad-spread));
    --color-grad-6: linear-gradient(var(--color-grad-deg), var(--color-scheme-6) 0%, white var(--color-grad-spread));
    --color-grad-7: linear-gradient(var(--color-grad-deg), var(--color-scheme-7) 0%, white var(--color-grad-spread));
    --color-grad-8: linear-gradient(var(--color-grad-deg), var(--color-scheme-8) 0%, white var(--color-grad-spread));
    --color-grad-pink: linear-gradient(var(--color-grad-deg), var(--color-scheme-pink) 0%, white var(--color-grad-spread));
    --color-grad-blue: linear-gradient(var(--color-grad-deg), var(--color-scheme-blue) 0%, white var(--color-grad-spread));
    --color-grad-grey: linear-gradient(var(--color-grad-deg), var(--color-scheme-grey) 0%, white var(--color-grad-spread));
    --color-grad-lightgrey: linear-gradient(var(--color-grad-deg), var(--color-scheme-lightgrey) 0%, white var(--color-grad-spread));
    --color-grad-1-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-1) -400%, white var(--color-grad-spread));
    --color-grad-2-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-2) -400%, white var(--color-grad-spread));
    --color-grad-3-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-3) -400%, white var(--color-grad-spread));
    --color-grad-4-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-4) -400%, white var(--color-grad-spread));
    --color-grad-5-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-5) -400%, white var(--color-grad-spread));
    --color-grad-6-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-6) -400%, white var(--color-grad-spread));
    --color-grad-7-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-7) -400%, white var(--color-grad-spread));
    --color-grad-8-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-8) -400%, white var(--color-grad-spread));
    --color-grad-pink-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-pink) -400%, white var(--color-grad-spread));
    --color-grad-blue-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-blue) -400%, white var(--color-grad-spread));
    --color-grad-grey-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-grey) -400%, white var(--color-grad-spread));
    --color-grad-grey-light: linear-gradient(var(--color-grad-deg), var(--color-scheme-lightgrey) -400%, white var(--color-grad-spread));
    
    --tab-grad-angle: 270deg;


    --tab-color-1: #303F9F;
    --tab-color-2: #e91e63;
    --tab-color-3: #009688;
    --tab-color-4: #ff5722;
    --tab-color-5: rgba(123,31,162,1);
    --tab-color-6: #607d8b;
    --tab-grad-1: linear-gradient(90deg, rgba(48,63,159,0.95) 0%, rgba(3,169,244,0.4) 200%);
    --tab-grad-2: linear-gradient(90deg, rgba(233,30,99,0.95) 0%, rgba(255,64,129,0.4) 150%);
    --tab-grad-3: linear-gradient(90deg, rgba(0,121,107,0.95) 0%, rgba(0,188,212,0.4) 200%);
    --tab-grad-4: linear-gradient(90deg, rgba(211,47,47,0.95) 0%, rgba(255,87,34,0.4) 150%);
    --tab-grad-5: linear-gradient(90deg, rgba(123,31,162,0.95) 0%, rgba(255,64,129,0.4) 200%);
    --tab-grad-6: linear-gradient(90deg, rgba(69,90,100,0.95) 0%, rgba(96,125,139,0.4) 150%);

    --tab-alpha-first: 1.0;
    --tab-alpha-second: 0.9;
    --tab-alpha-third: 0.8;
    --tab-alpha-fourth: 0.7;
    --tab-alpha-fifth: 0.6;
    --tab-alpha-sixth: 0.5;
    --tab-alpha-seventh: 0.45;
    --tab-alpha-eigth: 0.4;

    --tab-end-alpha-first: 0.8;
    --tab-end-alpha-second: 0.7;
    --tab-end-alpha-third: 0.6;
    --tab-end-alpha-fourth: 0.5;
    --tab-end-alpha-fifth: 0.4;
    --tab-end-alpha-sixth: 0.3;
    --tab-end-alpha-seventh: 0.27;
    --tab-end-alpha-eigth: 0.25;

    --tab-start-pct: 0%;
    --tab-end-pct: 100%;

    --darker-tab-start-pct: 50%;
    --darker-tab-end-pct: 100%;

    --darker-tab-alpha: 1;
    --darker-tab-end-alpha: 0.7;

    --tab-grad-angle2: 160deg;


    --tab-grad-n-first: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-1), var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-1), var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-second: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-2),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-2),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-third: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-3),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-3),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-fourth: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-4),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-4),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-fifth: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-5),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-5),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-sixth: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-6),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-6),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-seventh: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-7),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-7),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));
    --tab-grad-n-eigth: linear-gradient(var(--tab-grad-angle2), rgb(var(--color-raw-8),var(--darker-tab-alpha)) var(--darker-tab-start-pct), rgb(var(--color-raw-8),var(--darker-tab-end-alpha)) var(--darker-tab-end-pct));



    --tab-grad-1-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-1), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-1), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-1-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-1),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-1),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-1-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-1),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-1),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-1-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-1),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-1),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-1-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-1),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-1),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-1-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-1),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-1),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-2-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-2), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-2), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-2-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-2),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-2),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-2-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-2),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-2),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-2-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-2),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-2),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-2-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-2),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-2),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-2-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-2),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-2),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-3-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-3), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-3), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-3-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-3),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-3),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-3-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-3),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-3),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-3-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-3),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-3),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-3-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-3),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-3),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-3-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-3),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-3),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-4-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-4), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-4), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-4-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-4),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-4),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-4-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-4),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-4),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-4-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-4),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-4),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-4-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-4),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-4),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-4-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-4),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-4),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-5-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-5), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-5), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-5-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-5),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-5),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-5-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-5),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-5),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-5-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-5),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-5),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-5-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-5),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-5),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-5-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-5),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-5),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-6-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-6), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-6), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-6-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-6),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-6),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-6-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-6),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-6),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-6-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-6),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-6),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-6-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-6),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-6),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-6-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-6),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-6),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-7-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-7), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-7), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-7-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-7),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-7),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-7-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-7),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-7),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-7-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-7),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-7),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-7-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-7),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-7),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-7-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-7),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-7),var(--tab-end-alpha-sixth)) var(--tab-end-pct));

    --tab-grad-8-first: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-8), var(--tab-alpha-first)) var(--tab-start-pct), rgb(var(--color-raw-8), var(--tab-end-alpha-first)) var(--tab-end-pct));
    --tab-grad-8-second: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-8),var(--tab-alpha-second)) var(--tab-start-pct), rgb(var(--color-raw-8),var(--tab-end-alpha-second)) var(--tab-end-pct));
    --tab-grad-8-third: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-8),var(--tab-alpha-third)) var(--tab-start-pct), rgb(var(--color-raw-8),var(--tab-end-alpha-third)) var(--tab-end-pct));
    --tab-grad-8-fourth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-8),var(--tab-alpha-fourth)) var(--tab-start-pct), rgb(var(--color-raw-8),var(--tab-end-alpha-fourth)) var(--tab-end-pct));
    --tab-grad-8-fifth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-8),var(--tab-alpha-fifth)) var(--tab-start-pct), rgb(var(--color-raw-8),var(--tab-end-alpha-fifth)) var(--tab-end-pct));
    --tab-grad-8-sixth: linear-gradient(var(--tab-grad-angle), rgb(var(--color-raw-8),var(--tab-alpha-sixth)) var(--tab-start-pct), rgb(var(--color-raw-8),var(--tab-end-alpha-sixth)) var(--tab-end-pct));
}




.background-color-1 {
    background-color: var(--color-scheme-1);
}

.background-color-2 {
    background-color: var(--color-scheme-2);
}

.background-color-3 {
    background-color: var(--color-scheme-3);
}

.background-color-4 {
    background-color: var(--color-scheme-4);
}

.background-color-5 {
    background-color: var(--color-scheme-5);
}

.background-color-6 {
    background-color: var(--color-scheme-6);
}

.background-color-7 {
    background-color: var(--color-scheme-7);
}

.background-color-8 {
    background-color: var(--color-scheme-8);
}

.background-color-pink {
    background-color: var(--color-scheme-pink);
}

.background-color-blue {
    background-color: var(--color-scheme-blue);
}

.background-color-grey {
    background-color: var(--color-scheme-grey);
}

.background-color-lightgrey {
    background-color: var(--color-scheme-lightgrey);
}

.background-color-white {
    background-color: var(--color-scheme-white);
}

.background-grad-1 {
    background: var(--color-grad-1);
}

.background-grad-2 {
    background: var(--color-grad-2);
}

.background-grad-3 {
    background: var(--color-grad-3);
}

.background-grad-4 {
    background: var(--color-grad-4);
}

.background-grad-5 {
    background: var(--color-grad-5);
}

.background-grad-6 {
    background: var(--color-grad-6);
}

.background-grad-7 {
    background: var(--color-grad-7);
}

.background-grad-8 {
    background: var(--color-grad-8);
}

.background-grad-pink {
    background: var(--color-grad-pink);
}

.background-grad-blue {
    background: var(--color-grad-blue);
}

.background-grad-grey {
    background: var(--color-grad-grey);
}

.background-grad-lightgrey {
    background: var(--color-grad-lightgrey);
}

.color-1 {
    color: var(--color-scheme-1);
}

.color-2 {
    color: var(--color-scheme-2);
}

.color-3 {
    color: var(--color-scheme-3);
}

.color-4 {
    color: var(--color-scheme-4);
}

.color-5 {
    color: var(--color-scheme-5);
}

.color-6 {
    color: var(--color-scheme-6);
}

.color-7 {
    color: var(--color-scheme-7);
}

.color-8 {
    color: var(--color-scheme-8);
}

.color-pink {
    color: var(--color-scheme-pink);
}

.color-blue {
    color: var(--color-scheme-blue);
}

.color-grey {
    color: var(--color-scheme-grey);
}

.color-lightgrey {
    color: var(--color-scheme-lightgrey);
}

.color-text-1 {
    color: var(--color-scheme-text-1);
}

.color-text-2 {
    color: var(--color-scheme-text-2);
}

.color-text-3 {
    color: var(--color-scheme-text-3);
}

.color-text-4 {
    color: var(--color-scheme-text-4);
}

.color-text-5 {
    color: var(--color-scheme-text-5);
}

.color-text-6 {
    color: var(--color-scheme-text-6);
}

.color-text-7 {
    color: var(--color-scheme-text-7);
}

.color-text-8 {
    color: var(--color-scheme-text-8);
}

.color-text-pink {
    color: var(--color-scheme-text-pink);
}

.color-text-blue {
    color: var(--color-scheme-text-blue);
}

.color-text-grey {
    color: var(--color-scheme-text-grey);
}

.color-text-lightgrey {
    color: var(--color-scheme-text-lightgrey);
}

.color-text-link {
    color: var(--color-scheme-text-link);
}

.color-header-text {
    color: #FFF;
}

.opacity-eighty {
    opacity: 80%;
}

.opacity-sixty {
    opacity: 60%;
}

.opacity-fourty {
    opacity: 40%;
}