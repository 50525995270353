@media screen and (min-width: 992px) {
    .cardimages {
      width:auto;
      height:90px;
      max-width:90px;
      min-width:90px;
      min-height:90px;
    }

    .cards {
        width:auto;
        height:90px;
        min-height:90px;
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 992px) {  
    .cardimages {
      width:110px;
      height:110px;
      min-width:110px;
      min-height:110px;
    }

    .cards {
        width:auto;
        height:110px;
        min-height:110px;
        flex-basis:110;
    }
  }
  
  @media screen and (max-width: 768px) {
    .cardimages {
      width:130px;
      height:130px;
      min-width:130px;
      min-height:130px;
      flex-basis:110;
    }

    .cards {
        width:auto;
        height:130px;
        min-height:130px;
        flex-basis:130;
    }
  }
  