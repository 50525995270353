@import "colors.css";

body {
  margin: 0;
  padding: 0;
  font-family:'Montserrat', sans-serif !important;
  max-width: 100%;
  overflow-x: hidden;
}

/* .graphpaper {
  background: linear-gradient(to right, rgb(95, 205, 217) -350%, white 50%);
} */

.graphpaper {
  background: linear-gradient(to right, rgb(100, 130, 130) -600%, white 100%);
}

/* .graphpaper {
  background-color: #f9f9f9; 
  background-image: 
    linear-gradient(90deg, #eee 1px, transparent 1px), 
    linear-gradient(0deg, #eee 1px, transparent 1px); 
  background-size: 20px 20px; 
  background-position: top left;
  background-repeat: repeat; 
} */

@media screen and (min-width: 992px) {
  .titletext{
    min-height:0.5cm;
    font-size:0.41cm;
    color: red;
  }

  .navbarlogo {
    width:50%;
    min-width: 40%;
  }

  .navbartogglezone {
    display: inline;
    width:0px;
  }

  .navbarlogo2 {
    width:0%;
    min-width: 0%;
  }

  .bookcard {
    width:90;
    height:90;
  }

  .navbarbranding {
    padding:0px 0px;
    margin:0 16px 0 0;
    height:50;
    width:5;
  }

  .navbarheightguess {
    height:79px;
  }
}

.color-nav {
  /* //background: linear-gradient(165deg, #ececf0 -200%, #fefeff 100%); */
  /* //background: linear-gradient(165deg, #ededed -200%, #fefefe 100%); */
  background: linear-gradient(165deg, #ececf1 -200%, #fdfdff 100%);
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  navbar {
    background-color: #0c5460;
  }

  .navbarheightguess {
    height:78px;
  }

  .navbar-light .navbar-toggler-icon{
    /*background-image: url(https://i.stack.imgur.com/mSXoO.png)!important;*/
    width: 0.6cm;
    height: 0.6cm;
  }

  .titletext{
    font-size:0.75cm;
    color: green;
  }

  .navbartogglezone {
    display: inline;
  }

  .navbarlogo {
    width:0%;
    min-width: 0%;
  }

  .navbarlogo2 {
    width:50%;
    min-width: 40%;
  }

  .bookcard {
    width: 110;
    height: 110;
  }

  .navbarbranding {
    padding: 0px;
    margin:0;
    height:0;
  }
}

@media screen and (max-width: 1224px) {
  .card-size {
    min-height: 102px;
  }

  .centre-card-size {
    margin-top: 23px;
  }
}

@media screen and (min-width: 1225px) {
  .card-size {
    min-height: 92px;
  }

  .centre-card-size {
    margin-top: 20px;
  }
}

@media screen and (max-width: 768px) {
  .card-size {
    min-height: 102px;
  }

  .centre-card-size {
    margin-top: 25px;
  }

  .navbar-light .navbar-toggler-icon{
    /*background-image: url(https://i.stack.imgur.com/mSXoO.png)!important;*/
    width: 0.5cm;
    height: 0.5cm;
    margin-bottom: 2px;
  }

  .navbartogglezone {
    display: inline;
  }

  .navbarlogo {
    width:0%;
    min-width: 0%;
  }

  .navbarlogo2 {
    width:50%;
    min-width: 30%;
  }
 
  .titletext{
    font-size:0.75cm;
    color: blue;
  }

  #outlined-search{
    font-family: Roboto;
  }

  .bookcard {
    width:130;
    height:130;
  }

  .navbarbranding {
    padding:0;
    margin:0;
    height:0;
  }

  .navbarheightguess {
    height:78px;
  }
}

/*************
 * CSS for input fields on contact form
 *************/

 @media screen and (max-width: 991px) {
  .input_description {
      font-size: 1.5em !important;
      width: 100% !important;
      color: #555
  }
  .input_button {
      width: 100% !important;
      font-size: 1.5em !important;
      height: 2em;
      color: #222;
  }
  .input_text {
      width: 12em;
      height: 2em;
      font-size: 1.5em !important;
      color: #333;
  }
  .input_textarea {
      width: 12em;
      font-size: 1.5em !important;
      height: 4em;
      color: #333;
  }
  .input_label {
      width: 8em;
      font-size: 1.5em !important;
      color: #222;
  }
  .input_field {
      padding-bottom: 16px !important;
  }
  .text {
      font-size: 1.5em !important;
  }
}

.input_description {
  font-size: 1.25em;
  width: 100% !important;
  color: #555;
  border-color: #888;
  border-width: 1px;
  letter-spacing: 0.1em;
}

.input_details {
  font-size: 1.10em;
  width: 100% !important;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  letter-spacing: 0.1em;
}

.input_mini {
  font-size: 0.9em;
  width: 100% !important;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  letter-spacing: 0.1em;
}

.input_button {
  width: 100% !important;
  font-size: 1.25em;
  height: 2.5em;
  color: #444;
  border-color: #888;
  border-width: 1px
}

.input_text {
  width: 100% !important;
  height: 2.25em;
  font-size: 1.5em;
  padding-left: 8px;
  color: #444;
}

.input_textarea {
  width: 100% !important;
  height: 2.25em;
  font-size: 1.5em;
  height: 10em;
  padding-left: 8px;
  color: #444;
}

.input_label {
  width: 8em;
  font-size: 1.25em;
  color: #444;
}

.input_field {
  padding-bottom: 8px;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
  font-family: 'Montserrat','Titillium Web', sans-serif;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.mytabs li a { border-color: blue;background-color:#A5C967; }
.mytab1 { border-color: blue;background-color:#A5C967; }

/* .customTab1 {
  border:solid 1px blue;
  margin:0
}

.customTab2 {
  border-top-color:transparent !important;
  border:solid 1px magenta;
  margin:0
}

.customTab3 {
  border:solid 1px magenta;
  margin:0
} */

/* .customTabs ul > li > a {
  border-radius: 0;
  margin: -1;
} */








/* .customTabs a:nth-child(2) {
  border-radius: 20;
  margin: 0;
  background-color: white;
  color:magenta;
  border:solid 1px magenta;
  border-bottom-color: transparent;
}

.customTabs a.active:nth-child(2) {
  border-radius: 20;
  margin: -5;
  background-color: magenta;
  color:white;
  border:solid 1px magenta;
  border-bottom-color: transparent;
} */

/* .customTabs {
  border-bottom: 0 ! important
} */

/* .customTabs ul > li.active > a {

}

.customTabs ul > li:nth-child(1) > a {
  background-color: pink !important;
  color: pink;

}
.customTabs ul > li:nth-child(2) > a {
  background-color: lightblue;
  color: white;
}
.customTabs ul > li:nth-child(3) > a {
  background-color: lightgreen;
  color: white;
}

.customTabs ul > li.active:nth-child(1) > a,
.customTabs ul > li.active:nth-child(1) > a:hover,
.customTabs ul > li.active:nth-child(1) > a:focus {
  background-color: red;
  color: white;
  border-radius: 0;
} */

 