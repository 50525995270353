.bookcard-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    -webkit-tap-highlight-color: transparent;
    white-space: nowrap;
}

@media screen and (min-width: 600px) {
    .responsive-bookcard-header {
        font-size: 1.6em;
    }
}

@media screen and (max-width: 600px) {
    .responsive-bookcard-header {
        font-size: 1.3em
    }
}