.outline-button {
    display: inline-flex;
    border: 1px solid;
    padding: 5px 8px 5px 8px;
    margin: 10px 0px 0px 10px;
    border-radius: 2px;
    flex-grow: 4;
    min-width: 42px;
    cursor: pointer;
    opacity: 1;
    flex-basis: auto;
    flex-wrap: wrap;
}

.outline-button:hover {
    background: radial-gradient(#fff, #fafafa) !important;
    transition:1s ease;
}

.outline-button-text {
    margin: auto;
    color: black;
    opacity: 1;
    pointer-events: none;
}

.outline-buttons {
    padding: 2px 10px 10px 0px;
}