.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.aspectdiv {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden
}

.aspectimg {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}

.container {
  position: relative;
}

.example-enter {
  position: absolute;
  left: 10000; 
  opacity: 0;
}

.example-enter-active {
  opacity: 1;
  left: 0;
  transition: opacity 1000ms 600ms ease-in, left 1000ms 600ms ease-in;
}

.example-appear{
  opacity: 0;
}

.example-appear-active{
  opacity: 1;
  transition: opacity 1000ms 600ms ease-in;
}

.example-exit {
  opacity: 1;
}

.example-exit-active {
  opacity: 0;
  transition: opacity 400ms ease-out;
}

.video-react-big-play-button
{
  display: none !important;
}

@media screen and (max-width: 700px) {
  .sidebar {
      width: 100%;
      height: auto;
      position: relative;
  }
  .sidebar a {
      float: left;
  }
  div.content {
      margin-left: 0;
  }
  
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
  .sidebar a {
      text-align: center;
      float: none;
  }
}

